import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/work-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ul className="work-links">
  <li>
    <a href="https://github.com/SchanelyStudios/photo-forms">
      <i className="fab fa-github fa-lg"></i>
      View Github repo
    </a>
  </li>
    </ul>
    <p>{`To support my wife's photography business I created a custom questionnaire tool. This allows us to create our own questionnaires based on the site's look-and-feel and to collect data from customers accordingly.`}</p>
    <p>{`The application is built with React and Firebase for data interactions.`}</p>
    <h3>{`Fillable form`}</h3>
    <p>{`Clients are sent a link to fill out the appropriate questionnaire. When they save their submission they get a permalink they can use to review their data later.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/work/photo-forms-fillable.png",
        "alt": null
      }}></img></p>
    <h3>{`Form Editor`}</h3>
    <p>{`Application administrative users`}</p>
    <p><img parentName="p" {...{
        "src": "/images/work/photo-forms-editor.png",
        "alt": null
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      